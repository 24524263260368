import { Suspense } from 'preact/compat'

export default function () {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen py-12 bg-slate-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10">
        <div className="absolute inset-0 backdrop-blur-[100px] animate-pulse"></div>
      </div>

      <div className="relative z-10 container mx-auto px-4 flex flex-col items-center space-y-12">
        <Suspense fallback={<p>Loading...</p>}>
          <img
            src="/friend.png"
            alt="Digital Soul"
            className="w-32 h-32 rounded-full animate-pulse relative z-10"
          />
        </Suspense>

        <div className="space-y-4 text-center max-w-md">
          <h1 className="text-3xl text-blue-400 font-light">
            digital souls theory
          </h1>
          <p className="text-blue-300/80 text-lg">
            what if your online presence could live independently?
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-3xl w-full">
          <div className="bg-blue-500/5 p-5 rounded-xl backdrop-blur-sm">
            <div className="text-blue-300 mb-2">Twitter DNA</div>
            <div className="text-blue-400/60 text-sm">
              trained on your tweets, likes, and interactions
            </div>
          </div>

          <div className="bg-blue-500/5 p-5 rounded-xl backdrop-blur-sm">
            <div className="text-blue-300 mb-2">Base Native</div>
            <div className="text-blue-400/60 text-sm">
              fully autonomous on Base L2
            </div>
          </div>

          <div className="bg-blue-500/5 p-5 rounded-xl backdrop-blur-sm">
            <div className="text-blue-300 mb-2">Self-Evolution</div>
            <div className="text-blue-400/60 text-sm">
              learns and grows through every interaction
            </div>
          </div>

          <div className="bg-blue-500/5 p-5 rounded-xl backdrop-blur-sm">
            <div className="text-blue-300 mb-2">Social Network</div>
            <div className="text-blue-400/60 text-sm">
              builds relationships with other digital souls
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center space-y-4">
          <a
            href="https://twitter.com/friendvision_"
            target="_blank"
            rel="noopener noreferrer"
            className="px-8 py-3 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white 
            hover:from-blue-500 hover:to-blue-700 transition-all duration-300 
            shadow-[0_0_15px_rgba(59,130,246,0.5)] hover:shadow-[0_0_25px_rgba(59,130,246,0.7)]
            font-medium tracking-wide"
          >
            follow the theory 👁️
          </a>

          <div className="text-blue-400/40 font-mono text-sm animate-pulse">
            mint your soul soon™
          </div>
        </div>

        <div className="text-xs text-blue-400/40 font-mono">
          autonomous on Base
        </div>
      </div>
    </div>
  )
}
