import { FunctionComponent } from 'preact'
import AI from 'src/pages/AI'
import Main from 'src/pages/Main'
import Router from 'preact-router'

interface RouteProps {
  path: string
}

const MainRoute: FunctionComponent<RouteProps> = () => <Main />
const AIRoute: FunctionComponent<RouteProps> = () => <AI />

export default function App() {
  return (
    <Router>
      <MainRoute path="/" />
      <AIRoute path="/ai" />
    </Router>
  )
}
