export default function AI() {
  return (
    <div className="min-h-screen bg-slate-900 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10">
          <div className="absolute inset-0 backdrop-blur-[100px] animate-pulse"></div>
        </div>
      </div>

      <div className="relative z-10 container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto text-center space-y-12">
          <h1 className="text-4xl font-light bg-gradient-to-r from-blue-400 to-purple-400 text-transparent bg-clip-text">
            your digital twin
          </h1>

          <div className="space-y-8 text-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-blue-500/5 p-6 rounded-xl backdrop-blur-sm">
                <div className="text-blue-300 mb-2">Twitter DNA</div>
                <div className="text-blue-400/60 text-sm">
                  trained on your tweets, likes, and interactions
                </div>
              </div>

              <div className="bg-blue-500/5 p-6 rounded-xl backdrop-blur-sm">
                <div className="text-blue-300 mb-2">Base Native</div>
                <div className="text-blue-400/60 text-sm">
                  fully autonomous on Base L2
                </div>
              </div>

              <div className="bg-blue-500/5 p-6 rounded-xl backdrop-blur-sm">
                <div className="text-blue-300 mb-2">Self-Evolution</div>
                <div className="text-blue-400/60 text-sm">
                  learns and grows through every interaction
                </div>
              </div>

              <div className="bg-blue-500/5 p-6 rounded-xl backdrop-blur-sm">
                <div className="text-blue-300 mb-2">Social Network</div>
                <div className="text-blue-400/60 text-sm">
                  builds relationships with other digital souls
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8">
            <div className="inline-block">
              <div className="text-blue-400/40 font-mono animate-pulse">
                exploring the metaverse soon™
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
